<template>
  <div>
    <Header></Header>
    <div class="content">
      <div class="bread_nav flex-box">
        <div class="bread fs16 col3">
          当前位置：<router-link to="/">首页</router-link>><router-link to="/info">个人中心</router-link>><a
            href="javascript:;"
            >证书抬头</a
          >
        </div>
      </div>
      <div class="personal per_info pt30 m1440 flex-box flex-col-start">
        <div class="left_nav fs18">
          <router-link to="/info">基本信息</router-link>
          <router-link to="/certificate" class="active">证书抬头</router-link>
          <router-link to="/maintain_list">开票信息</router-link>
          <router-link to="/address_list">收件地址</router-link>
          <router-link to="/invoices_status">发票管理</router-link>
          <!-- mao 20250324 -->
          <!-- <router-link to="/contract_management">合同管理</router-link> -->
          <!-- end -->
          <router-link to="/change_password">修改密码</router-link>
          <router-link to="/change_phone">修改手机号</router-link>
          <a @click="cancellation()" class="cursor" href="javascript:;">删除账户</a>
        </div>
        <div class="right_box flex-grow-1">
          <div class="fs18 fwb pb30">
            证书抬头
            <a href="javascript:;" class="add_certificate fs16 ml0" @click="closePop()"
              >新增证书抬头</a
            >
          </div>
          <div v-if="cert_list.length == 0 && isShowNavBarLoading == true">
            <div class="no_data">
              <img src="../assets/static/icon/z_nothing.png" class="z_nothing" alt="" />
              <span>暂无数据</span>
            </div>
          </div>
          <div v-else>
            <ul class="address_list fs16">
              <li v-for="(v, k) in cert_list" :key="k">
                <div class="default" v-if="v.defaultFlag == 1">默认</div>
                <img
                  src="../assets/static/icon/icon_close.png"
                  @click="del_cert(v.id, k)"
                  :data-id="v.id"
                  class="icon_close"
                  alt=""
                />
                <!-- <div class="flex-box edit_btn fs16" @click="edit(v)">编辑</div> -->
                <div class="pb20">
                <span class="fs16 col9">中文公司名称：</span>
                <span class="fs16 col10">{{ v.companyName }}</span>
              </div>
              <div class="pb20">
                <span class="fs16 col9">中文公司详细地址：</span>
                <span class="fs16 col10">{{ v.address }}</span>
              </div>
              <div class="pb20">
                <span class="fs16 col9">英文公司名称：</span>
                <span class="fs16 col10">{{ v.companyNameEn }}</span>
              </div>
              <div class="pb20">
                <span class="fs16 col9">英文公司详细地址：</span>
                <span class="fs16 col10">{{ v.addressEn }}</span>
              </div>
              <div class="pb20">
                <span class="fs16 col9">国家：</span>
                <span class="fs16 col10">{{ v.country }}</span>
              </div>
                <!-- <div class="flex-box">
                  <div class="info_left">国家：</div>
                  <div>{{ v.country }}</div>
                </div> -->
              </li>
            </ul>
            <!-- <div class="nomore" v-if="isShowNavBarLoading">没有更多了~</div> -->
            <!-- sumCount是数据总数50条 perCount是每页有几条 pageTurn是页数切换的方法 每页显示多少条在组件pagination.vue里修改 -->
            <Pagination :sumCount="total" :perCount="pageSize" @pageTurn="pageTurn"></Pagination>
          </div>
        </div>
      </div>
    </div>
    <!-- 增加|删除 -->
    <Popup :visible="popup" :confirmText="false" :cancleText="false" @close="closePop">
      <div class="name_pop">
        <div class="flex-box name_title flex-between">
          <div class="fs20">{{ cert }}</div>
          <img
            src="../assets/static/icon/icon_close.png"
            class="icon_close"
            @click="closePop()"
            alt=""
          />
        </div>
        <div class="cirticle_info">
          <div class="must fs16 col9 info_title">中文名称(须用中文填写)</div>
          <input
            type="text"
            placeholder="请输入中文名称"
            class="input1 plr10"
            v-model="add_edit.companyName"
          />
          <div class="must fs16 col9 info_title">中文详细地址(须用中文填写)</div>
          <input
            type="text"
            class="input2 plr10"
            placeholder="请输入中文详细地址"
            v-model="add_edit.address"
          />
          <!-- mao modify 20250324 -->
          <!-- <div class="must fs16 col9 info_title">英文名称(须用英文填写)</div> -->
          <div class="fs16 col9 info_title">英文名称(购买IREC时，须用英文填写)</div>
          <input
            type="text"
            placeholder="请输入英文名称"
            class="input1 plr10"
            v-model="add_edit.companyNameEn"
          />
           <!-- <div class="must fs16 col9 info_title">英文详细地址(须用英文填写)</div> -->
           <div class="fs16 col9 info_title">英文详细地址(购买IREC时，须用英文填写)</div>
          <input
            type="text"
            class="input2 plr10"
            placeholder="请输入英文详细地址"
            v-model="add_edit.addressEn"
          />
          <div class="must fs16 col9 info_title">国家</div>
          <!-- <input
            type="text"
            class="input1 plr10"
            placeholder="请输入国家"
            v-model="add_edit.country"
          /> -->
          <div class="input1 select relative" >
            <div class="country" :class="coun_index== '-1' ? 'col9' : ''" @click="showCountries">{{ add_edit.country ? add_edit.country : '请选择' }}</div>
            <div class="country_box" v-show="showCountry">
              <div v-for="(item, index) in countries" :key="index" @click="chooseCountry(index)">{{ item.value }}</div>
            </div>
          </div>
          <div class="flex-box">
            <div class="fs16 col9 mr30">设为默认证书抬头</div>
            <div
              class="switch"
              :class="add_edit.defaultFlag == true ? 'on' : ''"
              @click="setDefault()"
            ></div>
          </div>
          <div class="save" @click="save_data()">保存</div>
        </div>
      </div>
    </Popup>
    <Footer></Footer>
  </div>
</template>
<script>
import Pagination from "@/components/pagination.vue";
import { Loading, Message, MessageBox } from "element-ui"; // 消息提示
import "element-ui/lib/theme-chalk/index.css";
import cookie from "vue-cookies";
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      popup: false,
      cert_list: [],
      pageSize: 10, //分页大小
      total: 0, //分页总数
      pageNum: 1, // 分页记录数
      isShowNavBarLoading: false, // 是否加载完
      add_edit: {}, //编辑添加
      cert: "新增抬头证书",
      countries: [],
      coun_index: "-1",
      showCountry:false,
    };
  },
  // 页面渲染完执行
  created() {
    let _that = this;
    // 获取抬头
    _that.get_certificate();
    _that.setting()
  },
  methods: {
    // 显示隐藏国家
    showCountries(){
      this.showCountry = !this.showCountry;
    },
    // 选择国家
    chooseCountry(index){
      this.coun_index = index;
      this.showCountry = false;
      let countries = this.countries
      this.add_edit.country = countries[index].value
    },

  RegExpString(str){
    // var p =/^((?!<|>|~|@|\|^|&|'|'').)*$/;
    // var p =/^((?!<|>|~|@|\|^|'|'').)*$/;
    var p = /^[A-Za-z0-9().,'&+-\s]+$/;
    return p.test(str);
},
    // 添加|编辑提交
    save_data() {
      let _that = this;
      let add_edit = _that.add_edit;
      let s_data = {
        companyName: add_edit.companyName,
        companyNameEn: add_edit.companyNameEn,
        addressEn: add_edit.addressEn,
        address: add_edit.address,
        country: add_edit.country,
      };
      if (add_edit.id && add_edit.id > 0) {
        s_data.id = add_edit.id;
      }
      if (add_edit.defaultFlag == true) {
        s_data.defaultFlag = "1";
      } else {
        s_data.defaultFlag = "0";
      }

      if(!s_data.companyName){
        Message.error('请输入中文公司名称')
					return;
				}
				if(!s_data.address){
					Message.error('请输入中文公司详细地址')
					return;
				}
       // mao del 20250324
       if(!s_data.companyNameEn){
        // Message.error('请输入英文公司名称')
				// return;
				}else{
         var result =  _that.RegExpString(s_data.companyNameEn)
         console.log(result)
         if(result==false){
          Message.error('公司名称只允许中文，数字，大小写字符，空格')
          return;
         }
        }
				// mao del 20250324
				if(!s_data.addressEn){
					// Message.error('请输入英文公司详细地址')
					// return;
				}else{
          var result2 =  _that.RegExpString(s_data.addressEn)
         if(result2==false){
          Message.error('地址只允许中文，数字，大小写字符，空格')
          return;
         }
        }
				if(!s_data.country){
					Message.error('国家不能为空')
					return;
				}
        let userinfo = cookie.get('huanbaoqiao_userinfo')
      add_edit.userId = userinfo.id
      _that.$api.saveCertificate(s_data).then((res) => {
        if (res.resCode == 0) {
          Message.success("保存成功");
          _that.popup = false;
          _that.cert = "新增抬头证书";
          _that.get_certificate();
        } else {
          Message.error(res.resMsg);
        }
      });
    },
    // 添加抬头
    closePop() {
      let _that = this;
      _that.add_edit = {
        defaultFlag: "0",
      };
      _that.cert = "新增抬头证书";
      _that.popup = !_that.popup;
    },
    // 默认抬头
    setDefault() {
      let _that = this;
      _that.add_edit.defaultFlag = !_that.add_edit.defaultFlag;
    },
    // 编辑
    edit(data) {
      let _that = this;
      _that.add_edit = data;
      _that.cert = "编辑抬头证书";
      _that.popup = !_that.popup;
    },
    // 删除证书
    del_cert(id, index) {
      let that = this;
      MessageBox.confirm("确定要删除嘛?", "温馨提示", { type: "warning" })
        .then((res) => {
          // 判断 是否是 确认
          if (res == "confirm") {
            that.$api.DelCertificate({ id: id }).then((res) => {
              if (res.resCode == 0) {
                Message.success("删除成功");
                let list = that.cert_list;
                list.splice(index, 1);
                that.cert_list = list;
                that.total = that.total - 1;
              } else {
                Message.error("删除失败");
              }
            });
          }
        })
        .catch((err) => {
          // console.log(err);
        });
    },
    // 分页
    pageTurn(page) {
      this.pageNum = page + 1;
      this.get_certificate();
    },
    // 获取抬头
    get_certificate() {
      var that = this;
     
      let page = that.pageNum;
      var s_param = {
        page:page,
        limit:that.pageSize,
        deleteFlag:"0"
      }
      this.$api.getCertificate(s_param).then((data_res) => {
        //console.log(data_res);
        if (data_res.resCode == 0) {
          if (JSON.stringify(data_res.root) === "{}" || data_res.root.length <= 0) {
            that.isShowNavBarLoading = true;
          } else {
            // 判断 是否 加载完毕
            var is_data = true;
            var totalCount = data_res.totalCount;
            var show_total = that.pageNum * that.pageSize;
            if (totalCount > show_total) {
              //当只有一页的时候展示
              is_data = true;
            } else {
              is_data = false;
            }
            that.total = totalCount;
            that.cert_list = data_res.root;
            that.isShowNavBarLoading = is_data;
          }
        } else {
          that.isShowNavBarLoading == true
          that.$message.error(data_res.msg);
        }
      });
    },

   //获取国家
   setting(){
    let that = this
    let data = {
      tag:'EQQUH0BOWWED',
					pkg_children:'com.dazz.business.model.setting.CommonSetting'
				}
      that.$api.apiPostSetting(data).then((res) => {
        if (res.resCode == 0) {
          if(res.root.length >0){
				 			 let root = res.root[0]
				 			 that.countries = root.children
				 		}
        }
      }).catch((err) => {
        console.log(err);
      });
    
   },
   
//删除
cancellation() {
      let that = this;
      MessageBox.confirm("是否确定删除当前账户?", "提示", { type: "warning" })
        .then((res) => {
          // 判断 是否是 确认
          if (res == "confirm") {
            let userinfo = cookie.get("huanbaoqiao_userinfo");
            that.$api.logOffAccount({ userId: userinfo.id}).then((res) => {
              if (res.resCode == 0) {
                that.$message.success('删除成功');
                cookie.remove("huanbaoqiao_token");
                cookie.remove("huanbaoqiao_userinfo");
                setTimeout(function () {
                  that.$router.push("/");
                }, 800)
              } else {
                that.$message.error(res.resMsg);
              }
            });

          }
        })
        .catch((err) => {
          //    console.log(err);
        });
    },

  },
};
</script>
